import { PageToolbar } from "@components/Common/PageToolbar";
import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import DutyPhonesGroup from "./DutyPhonesGroup";
import DutyPhoneModal from "./DutyPhoneModal";
import Spinner from "@components/Common/Spinner";
import { HomeContext } from "@services/react";
import { exportDutyPhonesToCSV } from "@services/export";
import { ExportButton } from "@components/Common/ExportButton";
import { Content } from "@components/Common/Content";

import s from "./style.module.scss";

const DutyPhonesPage = () => {
  const { notificationGroups: groups, dutyPhonesPageStore } = useContext(HomeContext);
  const { initDutyPhones, ready: isReady, dutyPhonesModalData: modalData } = dutyPhonesPageStore;

  useEffect(() => {
    void initDutyPhones();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isReady) {
    return <Spinner />;
  }

  return (
    <Content>
      <div className={s.dutyPhonesPage}>
        <PageToolbar className={s.menu}>
          <PageToolbar.Item align={"right"}>
            <ExportButton onClick={() => exportDutyPhonesToCSV(dutyPhonesPageStore.dutyPhones)} />
          </PageToolbar.Item>
        </PageToolbar>
        {groups.map(({ id }) => (
          <DutyPhonesGroup key={id} group={id} />
        ))}
        {modalData && <DutyPhoneModal initialValue={modalData} />}
      </div>
    </Content>
  );
};

export default observer(DutyPhonesPage);
