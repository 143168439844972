import { ModalDropdownSelectOption } from "@frontend/assaia-ui";
import { NewPts, Pts, PtsEventScheduleOptions } from "@models/pts";
import { getConfig } from "@di";
import { TurnaroundParameter } from "@frontend/configuration";
import { extractLocalized } from "@i18n";

export const getReferencePointsOptions = (target: TurnaroundParameter[]): ModalDropdownSelectOption[] =>
  target.map((value) => ({
    id: value.id,
    title: extractLocalized(value.label, value.id),
  }));

export const getReferencePointTitle = (key: PtsEventScheduleOptions["referencePoint"]): string => {
  const { ptsTurnaroundParams } = getConfig();
  const referencePoints = getReferencePointsOptions(ptsTurnaroundParams);
  return referencePoints.find((v) => v.id === key)?.title || "SOBT";
};

export const getShortReferencePointTitle = (key: PtsEventScheduleOptions["referencePoint"]): string => {
  if (key === "aircraft_start_ts") {
    return "T-START";
  } else if (key === "aircraft_end_ts") {
    return "T-END";
  }

  return getReferencePointTitle(key);
};

export const getPtsLabel = (id: string) => {
  const { ptsLabels } = getConfig();

  return extractLocalized(ptsLabels[id], id);
};

export const isNewPts = (data: Pts | NewPts): data is NewPts => {
  return !("id" in data);
};
