import React, { ComponentProps } from "react";
import { observer } from "mobx-react";
import { Icons24Export, SecondaryButton, IconLoader } from "@frontend/assaia-ui";

import { FormattedMessage } from "react-intl";

type Props = ComponentProps<typeof SecondaryButton> & {
  exporting?: boolean;
};

export const ExportButton = observer(({ onClick, exporting = false, disabled, children, ...restProps }: Props) => (
  <SecondaryButton
    leftIcon={exporting ? <IconLoader /> : <Icons24Export />}
    colorTheme="dark"
    noBackground={true}
    {...restProps}
    onClick={onClick}
    disabled={disabled || exporting}
  >
    {children || <FormattedMessage defaultMessage="Export" description="Export button text" />}
  </SecondaryButton>
));
