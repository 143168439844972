import { CSV_CONSTANTS } from "@services/csv/constants";
import { isFiniteNumber } from "@services/utils";

export const serializeValue = (value?: unknown): string => {
  let result: string = "";

  switch (typeof value) {
    case "string":
      result = value.trim().length === 0 ? "" : value;
      break;
    case "number":
      result = Number.isNaN(value) ? "0" : value.toString();
      break;
    case "undefined":
      result = "";
      break;
    case "object":
      if (Array.isArray(value)) {
        result = value.map(serializeValue).join(", ");
        break;
      }

      if (value === null) {
        break;
      }

      result = JSON.stringify(value);

      break;
  }

  return result.length ? `\"${result}\"` : ""; // eslint-disable-line no-useless-escape
};

export const parseValue = (input?: string): string => {
  const value = input?.trim();

  if (!value) {
    return "";
  }

  // Remove surrounding quotes and handle escaped quotes
  // if value starts and ends with a quote
  if (value.startsWith('"') && value.endsWith('"')) {
    return value.slice(1, -1).replace(/""/g, '"');
  }

  return value;
};

export const parseCsvLine = (line: string, separator: string): string[] => {
  const result: string[] = [];
  let current = "";
  let inQuotes = false;

  for (let index = 0; index < line.length; index++) {
    const char = line[index];
    const nextChar = line[index + 1];

    if (char === '"' && inQuotes && nextChar === '"') {
      // Handle escaped quote
      current += '"';
      index++;
    } else if (char === '"') {
      inQuotes = !inQuotes;
    } else if (char === separator && !inQuotes) {
      // Separator symbol outside of quotes is a cell separator
      result.push(current);
      current = "";
    } else {
      // Regular character
      current += char;
    }
  }

  // Push last cell
  result.push(current);

  return result.map(parseValue);
};

export const parseNumberValue = <T extends string | null, F = unknown>(value?: T, fallback?: F): F | number => {
  const derivedFallback = typeof fallback !== "undefined" ? fallback : 0;

  if (value === "" || value === null || typeof value === "undefined") {
    return derivedFallback;
  }

  const number = Number(value);

  return isFiniteNumber(number) ? number : derivedFallback;
};

export const parseBooleanValue = (value?: string): boolean => {
  return value?.trim().toLowerCase() === CSV_CONSTANTS.true;
};
