import { captureException, captureMessage, withScope } from "@sentry/browser";
import { isDev } from "@services/constants";
import { isFunction } from "lodash";
import { readFromLocalStorage } from "@services/localStorage";

// TODO: Improve types
export function reportError(message: string, error?: Error): void;
export function reportError(error: Error): void;
export function reportError(message: string | Error, error?: Error): void {
  const shouldLogToConsole = isDev;

  if (shouldLogToConsole) {
    if (error) {
      console.error(message, error);
    } else if (message instanceof Error) {
      console.error(message);
    } else {
      console.warn(message);
    }
  }

  if (error) {
    captureException(error);
  } else if (message instanceof Error) {
    captureException(message);
  } else {
    captureMessage(message);
  }
}

export function reportErrorWithTag(error: Error, [tagName, tagValue]: [string, any]) {
  withScope(function (scope) {
    scope.setTag(tagName, tagValue);
    captureException(error);
  });
}

export function reportMessage(msg: string) {
  captureMessage(msg);
}

const LOGGER_ENABLED_STORAGE_KEY = "LOGGER_ENABLED_STORAGE_KEY";

export const createLogger = (label: string, checkLoggerEnabled?: () => boolean) => {
  const checkerToUse = isFunction(checkLoggerEnabled)
    ? checkLoggerEnabled
    : () => readFromLocalStorage(LOGGER_ENABLED_STORAGE_KEY, false).value;

  return {
    log: (...args: Parameters<typeof console.log>) => {
      const newArgs = [`[${label}]`, ...args];
      if (checkerToUse()) {
        console.log(...newArgs);
      }
    },
  };
};
