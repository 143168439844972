import React, { useContext } from "react";
import { ModalDropdownScreen, ModalDropdownSelectOption, ModalLabel, ModalSeparator } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";

import { getUniqPropValues } from "@services/data/common";
import AlertTriggerType from "../IncidentTriggerType";
import RedStar from "@components/Common/RedStar";
import { IncidentModalStoreContext } from "@services/react";
import { getConfig } from "@di";
import { useExtractLocalized } from "@i18n/hooks";
import { formatEventName, formatObjectName, formatObjectPosition, formatObjectType } from "@services/data/incidents";

type Props = { sourceTitle: string };

const IncidentParameterSourceSelector = (props: Props) => {
  const intl = useIntl();
  const extractLocalized = useExtractLocalized();
  const { turnaroundEvents: allEvents = [], incidentTurnaroundParams = [] } = getConfig();
  const { sourceTitle } = props;
  const { selectedAttribute, setAttributeSettings, getInvalidFields, selectedAttributeKey } =
    useContext(IncidentModalStoreContext);
  const { objectName, objectType, objectPosition, eventName, type, parameterName } = selectedAttribute;
  const invalidFields = getInvalidFields(selectedAttributeKey);

  const objectNames = getUniqPropValues(allEvents, {}, "objectName");
  const nameDropdownItems: ModalDropdownSelectOption[] = objectNames.map((name) => ({
    title: formatObjectName(name),
    id: name,
  }));

  const ANY_ID = "**ANY**";
  const anyItem: ModalDropdownSelectOption = {
    title: intl.formatMessage({
      defaultMessage: "Any",
      description: "incident modal:attr selector",
    }),
    id: ANY_ID,
  };

  const objectTypes = getUniqPropValues(allEvents, { objectName }, "objectType");
  const typeDropdownItems: ModalDropdownSelectOption[] = objectName
    ? objectTypes.map((type) => ({
        title: formatObjectType(type),
        id: type,
      }))
    : [];
  if (typeDropdownItems.length) {
    typeDropdownItems.unshift(anyItem);
  }

  const objectPositions = getUniqPropValues(allEvents, { objectName, objectType }, "objectPosition");
  const positionDropdownItems: ModalDropdownSelectOption[] = objectName
    ? objectPositions.map((position) => ({
        title: formatObjectPosition(position),
        id: position,
      }))
    : [];
  if (positionDropdownItems.length) {
    positionDropdownItems.unshift(anyItem);
  }

  const eventNames = getUniqPropValues(allEvents, { objectName, objectType, objectPosition }, "eventType");
  const eventDropdownItems: ModalDropdownSelectOption[] = eventNames.map((name) => ({
    title: formatEventName(objectName, name),
    id: name,
  }));

  const turnaroundParameterDropdownItems: ModalDropdownSelectOption[] = incidentTurnaroundParams.map((p) => ({
    title: extractLocalized(p.label, p.id),
    id: p.id,
  }));

  const onAttributePropChanged = (data: Partial<typeof selectedAttribute>) => {
    if (data.objectName) {
      data.objectType = null;
      data.objectPosition = null;
      data.eventName = "";
    } else if (data.objectType) {
      if (data.objectType === ANY_ID) {
        data.objectType = null;
      }
      data.objectPosition = null;
      data.eventName = "";
    } else if (data.objectPosition) {
      if (data.objectPosition === ANY_ID) {
        data.objectPosition = null;
      }
      data.eventName = "";
    }
    setAttributeSettings(data);
  };

  return (
    <>
      <AlertTriggerType
        title={intl.formatMessage({
          defaultMessage: "attribute type",
          description: "incident modal:attr selector",
        })}
        triggerType={type}
        onTrigger={(v) => setAttributeSettings({ type: v })}
      />
      <ModalSeparator title={sourceTitle} />
      {type === "event" && (
        <>
          <ModalLabel
            title={intl.formatMessage({
              defaultMessage: "Object",
              description: "incident modal:attr selector",
            })}
          >
            <ModalDropdownScreen
              error={invalidFields.includes("objectName")}
              placeholder={intl.formatMessage({
                defaultMessage: "Select object...",
                description: "incident modal:attr selector",
              })}
              options={nameDropdownItems}
              onSelect={(v) => onAttributePropChanged({ objectName: v[0] })}
              title={intl.formatMessage({
                defaultMessage: "Object name",
                description: "incident modal:attr selector",
              })}
              selectedIds={[objectName]}
            />
          </ModalLabel>
          <ModalLabel
            title={intl.formatMessage({
              defaultMessage: "Object type",
              description: "incident modal:attr selector",
            })}
          >
            <ModalDropdownScreen
              error={invalidFields.includes("objectType")}
              options={typeDropdownItems}
              onSelect={(v) => onAttributePropChanged({ objectType: v[0] })}
              placeholder={intl.formatMessage({
                defaultMessage: "Select object type...",
                description: "incident modal:attr selector",
              })}
              title={intl.formatMessage({
                defaultMessage: "Object type",
                description: "incident modal:attr selector",
              })}
              selectedIds={objectType ? [objectType] : [ANY_ID]}
            />
          </ModalLabel>

          <ModalLabel
            title={intl.formatMessage({
              defaultMessage: "Object position",
              description: "incident modal:attr selector",
            })}
          >
            <ModalDropdownScreen
              error={invalidFields.includes("objectPosition")}
              options={positionDropdownItems}
              onSelect={(v) => onAttributePropChanged({ objectPosition: v[0] })}
              placeholder={intl.formatMessage({
                defaultMessage: "Select object position...",
                description: "incident modal:attr selector",
              })}
              title={intl.formatMessage({
                defaultMessage: "Object position",
                description: "incident modal:attr selector",
              })}
              selectedIds={objectPosition ? [objectPosition] : [ANY_ID]}
            />
          </ModalLabel>
          <ModalLabel
            title={intl.formatMessage({
              defaultMessage: "Event",
              description: "incident modal:attr selector",
            })}
          >
            <ModalDropdownScreen
              error={invalidFields.includes("objectName")}
              options={objectName ? eventDropdownItems : []}
              onSelect={(v) => onAttributePropChanged({ eventName: v[0] })}
              placeholder={intl.formatMessage({
                defaultMessage: "Select event...",
                description: "incident modal:attr selector",
              })}
              title={intl.formatMessage({
                defaultMessage: "Event",
                description: "incident modal:attr selector",
              })}
              selectedIds={eventName ? [eventName] : []}
            />
          </ModalLabel>
        </>
      )}
      {type === "turnaround_param" && (
        <ModalLabel
          title={
            <RedStar>
              <FormattedMessage defaultMessage="parameters" description="incident modal:attr selector" />
            </RedStar>
          }
        >
          <ModalDropdownScreen
            title={intl.formatMessage({
              defaultMessage: "Parameters",
              description: "incident modal:attr selector",
            })}
            error={invalidFields.includes("objectName")}
            options={turnaroundParameterDropdownItems}
            selectedIds={[parameterName]}
            onSelect={(v) =>
              setAttributeSettings({
                parameterName: v[0],
              })
            }
          />
        </ModalLabel>
      )}
    </>
  );
};

export default observer(IncidentParameterSourceSelector);
