import { Locales, Localized } from "@frontend/configuration";
import { useCallback, useContext } from "react";
import { IntlContext } from "@components/App/IntlProvider";

type LocalizedData<T, U = string> = undefined | null | Partial<Localized<T, U>>;

export const useLocale = () => {
  return useContext(IntlContext);
};

export const useExtractLocalized = () => {
  const { locale } = useLocale();

  // TS overload for better type inference
  function extract<T extends Locales, U = string>(localizedData: LocalizedData<T, U>, fallback: string): string;
  function extract<T extends Locales, U = string>(localizedData: LocalizedData<T, U>): string | undefined;
  function extract<T extends Locales, U = string>(
    localizedData: LocalizedData<T, U>,
    fallback?: string,
  ): U | string | undefined {
    return localizedData?.[locale] ?? fallback;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(extract, [locale]);
};
