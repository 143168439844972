import { cn } from "@services/react";
import React from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { ReactComponent as LogoSvg } from "./logo.svg";

const Logo = ({ sm, className }: { sm?: boolean; className?: string }) => {
  return <LogoSvg className={cn("logo", className)} viewBox={`0 0 ${sm ? 90 : 407} 84`} />;
};

export default observer(Logo);
