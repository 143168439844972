import { cn } from "@services/react";
import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { defineMessages, FormattedMessage } from "react-intl";

import { confirmModal } from "@services/syncedModal";

import styles from "./style.module.scss";

type Props = {
  group: string;
  selectedItems: string[];
  activeItems: string[];
  singularLabel?: string;
  pluralLabel?: string;
  onAddItem?: () => void;
  onToggleActive?: (val: boolean) => void;
  onDelete: (ids: string[]) => void;
  onCopy?: (ids: string[]) => void;
};

const messages = defineMessages({
  activateSelected: {
    defaultMessage: "Activate selected {pluralLabel}",
    description: "Group header",
  },
  deactivateSelected: {
    defaultMessage: "Deactivate selected {pluralLabel}",
    description: "Group header",
  },
});

const GroupHeader = ({
  group,
  onAddItem,
  onToggleActive,
  onDelete,
  selectedItems,
  activeItems,
  singularLabel = "item",
  pluralLabel = "items",
  onCopy,
}: Props) => {
  const onDeleteHandler = useCallback(async () => {
    const confirmed = await confirmModal();
    if (!confirmed) {
      return;
    }

    onDelete(selectedItems);
  }, [selectedItems, onDelete]);

  const onToggleActiveHandler = useCallback(
    async (val: boolean) => {
      const confirmed = await confirmModal(val ? messages.activateSelected : messages.deactivateSelected, {
        pluralLabel,
      });
      if (!confirmed) {
        return;
      }
      onToggleActive && onToggleActive(val);
    },
    [onToggleActive],
  );

  const onCopyHandler = useCallback(async () => {
    const confirmed = await confirmModal();
    if (!confirmed) {
      return;
    }

    onCopy && onCopy(selectedItems);
  }, [selectedItems, onCopy]);

  const activeSelected = selectedItems.every((v) => activeItems.includes(v));
  const inactiveSelected = selectedItems.every((v) => !activeItems.includes(v));

  return (
    <div className={styles.groupHeader}>
      <label>{group}</label>

      {!!selectedItems.length && (
        <div className={styles.selectedItems}>
          <span>
            <span className={styles.green}>
              <FormattedMessage
                defaultMessage="{count, plural, one {# {singularLabel}} other {# {pluralLabel}}} selected"
                description="group header selected"
                values={{
                  count: selectedItems.length,
                  singularLabel: singularLabel,
                  pluralLabel: pluralLabel,
                }}
              />
            </span>
          </span>

          <a onClick={onDeleteHandler} className={styles.deleteBtn}>
            <i className={"fas fa-trash"} />
          </a>

          {onCopy && (
            <a onClick={onCopyHandler} className={styles.toggleAactiveBtn}>
              <i className={"fas fa-copy"} />
            </a>
          )}

          {onToggleActive && activeSelected && (
            <a onClick={() => onToggleActiveHandler(false)} className={cn(styles.toggleAactiveBtn, styles.active)}>
              <i className="fas fa-power-off" />
            </a>
          )}

          {onToggleActive && inactiveSelected && (
            <a onClick={() => onToggleActiveHandler(true)} className={styles.toggleAactiveBtn}>
              <i className="fas fa-power-off" />
            </a>
          )}
        </div>
      )}

      {onAddItem && (
        <a onClick={onAddItem} className={styles.addItem}>
          <i className={"fas fa-plus"} />
          <FormattedMessage
            defaultMessage="Add {singularLabel}"
            values={{ singularLabel }}
            description="group header add"
          />
        </a>
      )}
    </div>
  );
};

export default observer(GroupHeader);
