import React, { useContext, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { debounce } from "lodash";
import { useIntl } from "react-intl";

import { HomeContext } from "@services/react";

import "./style.scss";

const SEARCH_QUERY_TIMEOUT = 600; // ms

const UserSearch = () => {
  const { searchQuery, setUserQuery, searchUsers } = useContext(HomeContext).usersPageStore;
  const intl = useIntl();

  const debouncedSearchUsers = useMemo(() => debounce(searchUsers, SEARCH_QUERY_TIMEOUT), []);

  useEffect(() => debouncedSearchUsers.cancel, []);

  return (
    <div className={"search"}>
      <input
        placeholder={intl.formatMessage({
          defaultMessage: "Search users (min 2 symbols)",
          description: "Search users input placeholder",
        })}
        value={searchQuery}
        onChange={(ev) => {
          const newQuery = ev.target.value;
          debouncedSearchUsers(searchQuery, newQuery);
          setUserQuery(newQuery);
        }}
      />
      <i className={"fas fa-search"} />
    </div>
  );
};
export default observer(UserSearch);
