export const EOL = "\r\n";
export const EOL_RE = /\r?\n/;

export const SEP = ",";

export const CSV_CONSTANTS = {
  true: "true",
  false: "false",
  No: "true",
  Yes: "false",
};

export const SCHEMA_VERSION_TOKEN = `<schema-version:{id}>${EOL}`;
export const SCHEMA_VERSION_RE = /<schema-version:([\s\S]*?)>/;
