import { cn } from "@services/react";
import React from "react";
import { observer } from "mobx-react";

import "./style.scss";

type Props = {
  className?: string;
};

const Spinner = ({ className }: Props) => {
  return <div className={cn("spinner", className)} />;
};

export default observer(Spinner);
