import { notificationsApiClient as ptsApiClient } from "@api/client";
import { camelCaseKeys, snakeCaseKeys } from "@api/parsers";
import di from "@di";
import { NewPts, Pts, PtsApiSpec, PtsSchedule } from "@models/pts";
import { formatNotificationGroupLabel } from "@services/data/incidents";
import { isNewPts } from "@services/ptsUtils";
import { toLowerCase, toUpperCase } from "@services/utils";
import { AxiosResponse } from "axios";
import { uniqBy } from "lodash";

const ptsApiUrl = () => {
  const { authority } = di.resolve("openIdConfig");
  return (new URL(authority).origin + "/api/pts/").replace("id.", "");
};
type RawPts = PtsApiSpec["schemas"]["PTS"];

const normalizePts = (data: RawPts): Pts => {
  const result = camelCaseKeys(data);

  result.filters = {
    ...result.filters,
    requiredTurnaroundLength: toUpperCase(result.filters.requiredTurnaroundLength),
    inboundFlightStatus: toUpperCase(result.filters.inboundFlightStatus),
    outboundFlightStatus: toUpperCase(result.filters.outboundFlightStatus),
  };

  result.schedules = (data.schedules || [])?.map((schedule) => {
    const newValue = camelCaseKeys(schedule);

    if (!newValue.start.referencePoint) {
      newValue.start.referencePoint = "sobt";
    }

    if (!newValue.end.referencePoint) {
      newValue.end.referencePoint = "sobt";
    }

    return newValue as PtsSchedule;
  });

  result.airline = {
    id: <string>data.airline,
    roleName: formatNotificationGroupLabel(<string>data.airline),
  };

  return result;
};

const normalizePtsResponse = ({ data }: AxiosResponse<RawPts>): Pts => normalizePts(data);

const denormalizePts = (ptsData: Pts | NewPts): PtsApiSpec["schemas"]["PTS"] => {
  const { schedules, filters, active, airline, description } = ptsData;

  const result: PtsApiSpec["schemas"]["PTS"] = {
    active,
    airline: airline.id,
    description,
  };

  if (!isNewPts(ptsData)) {
    result.id = ptsData.id;
  }

  result.filters = {
    excluded_aircraft_types: filters.excludedAircraftTypes,
    required_aircraft_types: filters.requiredAircraftTypes,
    inbound_flight_status: toLowerCase(filters.inboundFlightStatus),
    outbound_flight_status: toLowerCase(filters.outboundFlightStatus),
    required_turnaround_length: toLowerCase(filters.requiredTurnaroundLength),
  };

  result.schedules = schedules.map(({ opName, start, end, ...rest }) => ({
    ...rest,
    op_name: "",
    start: snakeCaseKeys(start),
    end: snakeCaseKeys(end),
  }));
  result.schedules = uniqBy(result.schedules, "id");

  return result;
};

const getPtsList = () =>
  ptsApiClient.get<Pts[], AxiosResponse<RawPts[]>, RawPts[]>(ptsApiUrl()).then(({ data }) => {
    return data.map(normalizePts).map<Pts>((pts) => ({
      ...pts,
      schedules: uniqBy(pts.schedules, "id"),
    }));
  });

const getPtsByGroup = (group: string) =>
  ptsApiClient.get<Pts, AxiosResponse<RawPts>>(`${ptsApiUrl()}/${group}`).then(normalizePtsResponse);

const createPts = (ptsData: NewPts): Promise<Pts> =>
  ptsApiClient.post<Pts, AxiosResponse<RawPts>>(ptsApiUrl(), denormalizePts(ptsData)).then(normalizePtsResponse);

const patchPts = ({ id, ...ptsData }: Pts) => {
  return ptsApiClient
    .patch<Pts, AxiosResponse<RawPts>>(ptsApiUrl() + id, denormalizePts(ptsData))
    .then(normalizePtsResponse);
};

const createPtsList = (list: NewPts[]): Promise<Pts[]> => {
  return Promise.all(list.filter(isNewPts).map(createPts));
};

const createOrUpdatePtsList = (list: (Pts | NewPts)[]): Promise<Pts[]> => {
  return Promise.all(
    list.map((ptsData) => {
      if (isNewPts(ptsData)) {
        return createPts(ptsData);
      }

      return patchPts(ptsData);
    }),
  );
};

const deletePtsList = (data: string[]) => {
  return Promise.all(data.map((id) => ptsApiClient.delete(ptsApiUrl() + id)));
};

const patchPtsList = (list: Pts[]) => {
  return Promise.all(
    list.map(({ id, ...ptsData }) => {
      return ptsApiClient.patch<Pts>(ptsApiUrl() + id, denormalizePts(ptsData));
    }),
  );
};

export const ptsApi = {
  getPtsList,
  getPtsByGroup,
  createPts,
  patchPts,
  deletePtsList,
  patchPtsList,
  createPtsList,
  createOrUpdatePtsList,
};

export type TPtsApi = typeof ptsApi;
