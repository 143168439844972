import { merge } from "lodash";
import { PtsSchedule } from "@models/pts";

import { getLatestSchema } from "./schema";
import { parseCsvLine, parseValue, serializeValue } from "./utils";

// Static for this time
const defaultSchema = getLatestSchema();

export const ptsScheduleToCSV = (ptsSchedules: PtsSchedule[], schema = defaultSchema): string => {
  if (!schema) {
    throw new Error("[ptsScheduleToCSV] Missing schema");
  }

  const headerRow = schema.schedules.map((column) => serializeValue(column.title)).join(schema.separator);

  const rows = [headerRow];

  for (const schedule of ptsSchedules) {
    rows.push(
      schema.schedules
        .map((column) => {
          return serializeValue(column.export(schedule));
        })
        .join(schema.separator),
    );
  }

  return rows.join(schema.eol);
};

export const ptsScheduleFromCSV = (csv: string, schema = defaultSchema): PtsSchedule[] => {
  const rows = csv.split(schema.eolRe).filter(Boolean);
  const [headerRow, ...dataRows] = rows;

  if (!csv?.length) {
    return [];
  }

  if (!schema) {
    throw new Error("[ptsScheduleFromCSV] Missing schema");
  }

  const columns = parseCsvLine(headerRow, schema.separator).map(parseValue);

  return dataRows.map((row) => {
    const values = row.split(schema.separator).map(parseValue);

    if (values.length !== columns.length) {
      throw new Error(`Row length doesn't match header length: ${values.length} !== ${columns.length}`);
    }

    const result = <PtsSchedule>{};

    for (let index = 0; index < values.length; index++) {
      const value = values[index];
      const column = schema.schedules[index];

      if (column) {
        merge(result, column.import(value));
      } else {
        throw new Error(`Unknown column: ${columns[index]}`);
      }
    }

    return result;
  });
};
