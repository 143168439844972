import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import Spinner from "@components/Common/Spinner";

import { HomeContext } from "@services/react";
import { Content } from "@components/Common/Content";

import { PtsMainModal } from "../PtsMainModal";
import { Toolbar } from "./Toolbar";
import { PtsGroup } from "./PtsGroup";

export const PtsListPage = observer(() => {
  const { ptsPageStore, ptsGroups } = useContext(HomeContext);
  const { initPtsList, ready, ptsData } = ptsPageStore;

  useEffect(() => {
    void initPtsList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!ready) {
    return <Spinner />;
  }

  return (
    <Content>
      <Toolbar />
      <div>
        {ptsGroups.map((group) => (
          <PtsGroup group={group} key={group.id} />
        ))}
      </div>
      {ptsData && <PtsMainModal ptsData={ptsData} />}
    </Content>
  );
});
