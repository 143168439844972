import React, { ChangeEvent, useContext, useState } from "react";
import { observer } from "mobx-react";
import { defineMessage, useIntl } from "react-intl";

import { ptsListFromCSV } from "@services/csv/ptsList";
import { HomeContext } from "@services/react";
import { ImportButton as BaseImportButton } from "@components/Common/ImportButton";

export const PtsImportButton = observer(() => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {
    setUIAlert,
    ptsPageStore: { ready, uploadPtsFromCSV },
  } = useContext(HomeContext);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setIsProcessing(true);
    const file = event.target?.files?.item(0);

    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", (progressEvent) => {
        const text = progressEvent.target?.result as string;

        void parseCSV(text);
      });

      reader.readAsText(file);
    }
  };

  const parseCSV = async (text: string) => {
    try {
      const data = ptsListFromCSV(text);
      await uploadPtsFromCSV(data);

      setUIAlert({
        message: defineMessage({
          defaultMessage: "CSV imported successfully",
          description: "CSV file imported successfully notification message",
        }),
        type: "info",
      });
    } catch (error) {
      console.error(error);
      setUIAlert({
        message: defineMessage({
          defaultMessage: "Error parsing CSV file",
          description: "Error parsing CSV file",
        }),
        type: "error",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const isDisabled = !ready || isProcessing;

  return (
    <BaseImportButton inProgress={isProcessing} disabled={isDisabled} onFilesSelect={handleFileUpload}>
      {intl.formatMessage({
        defaultMessage: "Import CSV",
        description: "Import CSV button label",
      })}
    </BaseImportButton>
  );
});
