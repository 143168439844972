import { IconLoader, Icons24Export, SecondaryButton } from "@frontend/assaia-ui";

import { isFunction } from "@services/utils";
import { observer } from "mobx-react";
import React, { ChangeEvent, ComponentProps, createRef, MouseEvent, ReactNode } from "react";
import { useIntl } from "react-intl";

import s from "./style.module.scss";

type Props = {
  onFilesSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inProgress?: boolean;
  buttonProps?: ComponentProps<typeof SecondaryButton>;
  accept?: string;
  children?: ReactNode;
};

export const ImportButton = observer(
  ({ onFilesSelect, disabled = false, inProgress = false, buttonProps = {}, children, accept = ".csv" }: Props) => {
    const intl = useIntl();
    const inputRef = createRef<HTMLInputElement>();

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (!inputRef.current || disabled || inProgress) {
        return;
      }

      inputRef.current.value = "";
      isFunction(buttonProps?.onClick) && buttonProps?.onClick(event);
      inputRef.current.click();
    };

    const label =
      children ||
      buttonProps?.children ||
      intl.formatMessage({
        defaultMessage: "Import",
        description: "Import button default text",
      });

    return (
      <>
        <SecondaryButton
          leftIcon={inProgress ? <IconLoader /> : <Icons24Export className={s.exportIcon} />}
          colorTheme="dark"
          noBackground={true}
          {...buttonProps}
          disabled={disabled || inProgress}
          onClick={handleButtonClick}
        >
          {label}
        </SecondaryButton>
        <input type="file" accept={accept} ref={inputRef} className={s.input} onChange={onFilesSelect} />
      </>
    );
  },
);
