import { TResourceValue } from "@models/common";

export const TOW_ON = "TOW_ON";
export const TOW_OFF = "TOW_OFF";
export const INBOUNDS_ONLY = "INBOUNDS_ONLY";
export const FULL_FLIGHTS_ONLY = "FULL_FLIGHTS_ONLY";
export const TOW_ON_FULL_FLIGHTS_ONLY = "TOW_ON_FULL_FLIGHTS_ONLY";

export type TFlightStatusFilterValue =
  | typeof TOW_ON
  | typeof TOW_OFF
  | typeof INBOUNDS_ONLY
  | typeof FULL_FLIGHTS_ONLY
  | typeof TOW_ON_FULL_FLIGHTS_ONLY;

export const SET = "SET";
export const EMPTY = "EMPTY";

export const SEVERITY_LOW = "LOW";
export const SEVERITY_MEDIUM = "MEDIUM";
export const SEVERITY_HIGH = "HIGH";

export const SHORT = "SHORT";
export const LONG = "LONG";

export const INBOUND_FLIGHT_STATUS_KEYS = [SET, EMPTY] as const;
export const OUTBOUND_FLIGHT_STATUS_KEYS = [SET, EMPTY] as const;

export const TURN_LENGTHS_KEYS = [SHORT, LONG] as const;
export const SEVERITY_KEYS = [SEVERITY_LOW, SEVERITY_MEDIUM, SEVERITY_HIGH] as const;
export const TRIGGER_KEYS = ["happened", "not_happened"] as const;

export type Trigger = (typeof TRIGGER_KEYS)[number];

const UNIFIED = "unified";
const SAFETY_EVENT = "safety-event";
const SAFETY_ALERT = "safety-alert";
const PUSHBACK_SPEED_EVENT = "pushback-speed-safety-event";
const CATERING_HANDRAILS_EVENT = "catering-handrail-safety-event";
const BELT_GUARDRAIL_EVENT = "belt-guardrail-safety-event";

type WithoutId<T> = Omit<T, "id">;

export type Severity = typeof SEVERITY_HIGH | typeof SEVERITY_MEDIUM | typeof SEVERITY_LOW;

export type TriggerKeys = (typeof TRIGGER_KEYS)[number];

export type TurnaroundEventAttribute = {
  type: "event";
  objectName: string;
  objectType: string | null;
  eventName: string;
  objectPosition: string | null;
};

export type TurnaroundParameterAttribute = {
  type: "turnaround_param";
  parameterName: string;
};

export type TurnaroundFlightParameterAttribute = {
  type: "flight_parameter";
  parameterName: string;
};

export type MergedTurnaroundAttributeType = TurnaroundEventAttribute["type"] | TurnaroundParameterAttribute["type"];

export type MergedTurnaroundAttribute = {
  type: MergedTurnaroundAttributeType;
} & Omit<TurnaroundEventAttribute, "type"> &
  Omit<TurnaroundParameterAttribute, "type"> &
  Pick<TurnaroundPointInTime, "timeShift">;

export type TurnaroundAttribute =
  | TurnaroundEventAttribute
  | TurnaroundParameterAttribute
  | TurnaroundFlightParameterAttribute;

export type TurnaroundPointInTime = {
  timeShift: number;
  turnaroundAttribute: TurnaroundAttribute;
};

type SearchInterval = {
  startEvent: TurnaroundAttribute;
  startTimeShift?: number;
  endEvent: TurnaroundAttribute;
  endTimeShift?: number;
};

type UnifiedIncidentConfigData = {
  incidentType: typeof UNIFIED;
  type: typeof UNIFIED;
  detectedAttributes: TurnaroundAttribute[];
  endRangeBoundary: TurnaroundPointInTime | null;
  firingPointInTime: TurnaroundPointInTime | null;
  startRangeBoundary: TurnaroundPointInTime;
  trigger: Trigger;
};

type SafetyEventConfigData = {
  intervalToSearchIn?: SearchInterval;
  inverseOperation?: boolean;
  minRangeDuration?: number;
  opNameToSearchFor?: string;
  trigger: Trigger;
  type: typeof SAFETY_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type SafetyAlertIncidentData = {
  safetyEventConfigId?: string;
  type: typeof SAFETY_ALERT;
  incidentType: typeof SAFETY_ALERT;
};

type CateringHandrailConfigData = {
  greenOperations?: string[];
  redOperations?: string[];
  type: typeof CATERING_HANDRAILS_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type PushbackSpeedConfigData = {
  pushbackSpeedLimit: number;
  type: typeof PUSHBACK_SPEED_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type GuardrailConfigData = {
  type: typeof BELT_GUARDRAIL_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type BaseIncidentConfig = {
  id: string;
  active: boolean;
  customText: string | null;
  excludedAircraftTypes: string[];
  excludedStands: string[];
  group: TResourceValue;
  inboundFlightStatus: (typeof INBOUND_FLIGHT_STATUS_KEYS)[number] | null;
  outboundFlightStatus: (typeof OUTBOUND_FLIGHT_STATUS_KEYS)[number] | null;
  requiredAircraftTypes: string[];
  requiredStands: string[];
  severity: Severity;
  requiredTurnaroundLength: (typeof TURN_LENGTHS_KEYS)[number] | null;
  incidentType: typeof UNIFIED | typeof SAFETY_EVENT | typeof SAFETY_ALERT;

  requiredAirlineIcaoAndIata: string[];
  excludedAirlineIcaoAndIata: string[];
};

export type UnifiedIncidentConfig = BaseIncidentConfig & {
  data: UnifiedIncidentConfigData;
};

export type SafetyAlertIncidentConfig = BaseIncidentConfig & {
  data: SafetyAlertIncidentData;
};

export type SafetyEventIncidentConfig = BaseIncidentConfig & {
  data: SafetyEventConfigData | PushbackSpeedConfigData | CateringHandrailConfigData | GuardrailConfigData;
};

export type NewUnifiedIncidentConfig = WithoutId<UnifiedIncidentConfig>;

export type NewSafetyEventIncidentConfig = WithoutId<SafetyEventIncidentConfig>;

export type NewSafetyAlertIncidentConfig = WithoutId<SafetyAlertIncidentConfig>;

export type IncidentConfig = UnifiedIncidentConfig | SafetyEventIncidentConfig | SafetyAlertIncidentConfig;

export type NewIncidentConfig = NewUnifiedIncidentConfig | NewSafetyEventIncidentConfig | NewSafetyAlertIncidentConfig;
