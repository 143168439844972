import { INBOUND_FLIGHT_STATUS_KEYS, OUTBOUND_FLIGHT_STATUS_KEYS, TURN_LENGTHS_KEYS } from "@models/incidentConfig";
import { NewPts, Pts, ptsDirection, PtsSchedule } from "@models/pts";
import { DeepPartial } from "@services/type-utils";

import { CSV_CONSTANTS, EOL, EOL_RE, SCHEMA_VERSION_RE, SCHEMA_VERSION_TOKEN, SEP } from "./constants";
import { parseNumberValue, parseBooleanValue, parseValue } from "./utils";

type ExportFunction<T> = (data: T) => string | number | null;
type ImportFunction<T> = (value: string) => DeepPartial<T>;

type CSVColumn<T> = {
  title: string;
  export: ExportFunction<T>;
  import: ImportFunction<T>;
};

export type CSVSchemas = Readonly<{
  [version: string]: {
    version: string;
    separator: string;
    eol: string;
    eolRe: RegExp;
    ptsStartToken: string;
    ptsEndToken: string;
    getVersion: () => string;
    wrapPtsBlock: (id: string | null, content: string) => string;
    wrapScheduleBlock: (content: string) => string;
    schedulesStartToken: string;
    schedulesEndToken: string;
    ptsBlockRegex: RegExp;
    schedulesBlockRegex: RegExp;
    schedules: CSVColumn<PtsSchedule>[];
    pts: CSVColumn<NewPts | Pts>[];
  };
}>;

export const SCHEMAS: CSVSchemas = {
  "1.0": {
    version: "1.0",

    getVersion: () => "1.0",

    separator: SEP,
    eol: EOL,
    eolRe: EOL_RE,

    ptsStartToken: "<pts-start{id}>",
    ptsEndToken: "<pts-end{id}>",
    schedulesStartToken: "<schedules-start>",
    schedulesEndToken: "<schedules-end>",

    wrapPtsBlock: function (id, content) {
      const startToken = this.ptsStartToken.replace("{id}", id ? `:${id}` : "");
      const endToken = this.ptsEndToken.replace("{id}", id ? `:${id}` : "");

      return [startToken, content, endToken].join(this.eol);
    },

    wrapScheduleBlock: function (content) {
      return [this.schedulesStartToken, content, this.schedulesEndToken].join(this.eol);
    },

    ptsBlockRegex: /<pts-start(?::([^>]+))?>([\s\S]*?)<pts-end(?::([^>]+))?>/g,
    schedulesBlockRegex: /<schedules-start>([\s\S]*?)<schedules-end>/g,

    schedules: [
      {
        title: "id",
        export: (schedule) => schedule.id,
        import: (value) => ({ id: value }),
      },
      {
        title: "operation name",
        export: (schedule) => schedule.opName,
        import: (value) => ({ opName: value }),
      },
      {
        title: "direction",
        export: (schedule) => schedule.direction || "",
        import: (value) => {
          let direction = null;

          if (ptsDirection.some((dir) => dir === value)) {
            direction = <PtsSchedule["direction"]>value;
          }

          return { direction };
        },
      },
      {
        title: "uninterruptible",
        export: (schedule) => (schedule.uninterruptible ? CSV_CONSTANTS.true : CSV_CONSTANTS.false),
        import: (value) => ({ uninterruptible: parseBooleanValue(value) }),
      },
      // PtsSchedule['start']
      {
        title: "start: ideal time",
        export: (schedule) => schedule.start.idealTime,
        import: (value) => ({ start: { idealTime: parseNumberValue(value) } }),
      },
      {
        title: "start: reference point",
        export: (schedule) => schedule.start.referencePoint,
        import: (value) => ({ start: { referencePoint: value || "sobt" } }),
      },
      {
        title: "start: orange interval start",
        export: (schedule) => schedule.start.orangeInterval.start,
        import: (value) => ({ start: { orangeInterval: { start: parseNumberValue(value, null) } } }),
      },
      {
        title: "start: orange interval end",
        export: (schedule) => schedule.start.orangeInterval.end,
        import: (value) => ({ start: { orangeInterval: { end: parseNumberValue(value, null) } } }),
      },
      {
        title: "start: red interval start",
        export: (schedule) => schedule.start.redInterval.start,
        import: (value) => ({ start: { redInterval: { start: parseNumberValue(value, null) } } }),
      },
      {
        title: "start: red interval end",
        export: (schedule) => schedule.start.redInterval.end,
        import: (value) => ({ start: { redInterval: { end: parseNumberValue(value, null) } } }),
      },
      // PtsSchedule['end']
      {
        title: "end: ideal time",
        export: (schedule) => schedule.end.idealTime,
        import: (value) => ({ end: { idealTime: parseNumberValue(value) } }),
      },
      {
        title: "end: reference point",
        export: (schedule) => schedule.end.referencePoint,
        import: (value) => ({ end: { referencePoint: value || "sobt" } }),
      },
      {
        title: "end: orange interval start",
        export: (schedule) => schedule.end.orangeInterval.start,
        import: (value) => ({ end: { orangeInterval: { start: parseNumberValue(value, null) } } }),
      },
      {
        title: "end: orange interval end",
        export: (schedule) => schedule.end.orangeInterval.end,
        import: (value) => ({ end: { orangeInterval: { end: parseNumberValue(value, null) } } }),
      },
      {
        title: "end: red interval start",
        export: (schedule) => schedule.end.redInterval.start,
        import: (value) => ({ end: { redInterval: { start: parseNumberValue(value, null) } } }),
      },
      {
        title: "end: red interval end",
        export: (schedule) => schedule.end.redInterval.end,
        import: (value) => ({ end: { redInterval: { end: parseNumberValue(value, null) } } }),
      },
    ],

    pts: [
      {
        title: "id",
        export: (pts) => pts.id || "",
        import: (value) => ({ id: value || undefined }),
      },
      {
        title: "airline id",
        export: (pts) => pts.airline.id,
        import: (value) => ({ airline: { id: value } }),
      },
      {
        title: "airline roles",
        export: (pts) => pts.airline.roleName,
        import: (value) => ({ airline: { roleName: value } }),
      },
      {
        title: "active",
        export: (pts) => (pts.active ? CSV_CONSTANTS.true : CSV_CONSTANTS.false),
        import: (value) => ({ active: parseBooleanValue(value) }),
      },
      {
        title: "description",
        export: (pts) => pts.description,
        import: (value) => ({ description: value }),
      },
      {
        title: "required aircraft types",
        export: (pts) => pts.filters.requiredAircraftTypes.map(parseValue).join(", "),
        import: (value) => ({
          filters: { requiredAircraftTypes: value.split(/\s*,\s*/) },
        }),
      },
      {
        title: "excluded aircraft types",
        export: (pts) => pts.filters.excludedAircraftTypes.join(", "),
        import: (value) => ({
          filters: { excludedAircraftTypes: value.split(/\s*,\s*/) },
        }),
      },
      {
        title: "required turnaround length",
        export: (pts) => pts.filters.requiredTurnaroundLength,
        import: (value) => {
          let requiredTurnaroundLength = null;

          if (TURN_LENGTHS_KEYS.some((key) => key === value)) {
            requiredTurnaroundLength = <(typeof TURN_LENGTHS_KEYS)[number]>value;
          }

          return { filters: { requiredTurnaroundLength } };
        },
      },
      {
        title: "inbound flight status",
        export: (pts) => pts.filters.inboundFlightStatus,
        import: (value) => {
          let inboundFlightStatus = null;

          if (INBOUND_FLIGHT_STATUS_KEYS.some((key) => key === value)) {
            inboundFlightStatus = <(typeof INBOUND_FLIGHT_STATUS_KEYS)[number]>value;
          }

          return { filters: { inboundFlightStatus } };
        },
      },
      {
        title: "outbound flight status",
        export: (pts) => pts.filters.outboundFlightStatus,
        import: (value) => {
          let outboundFlightStatus = null;

          if (OUTBOUND_FLIGHT_STATUS_KEYS.some((key) => key === value)) {
            outboundFlightStatus = <(typeof OUTBOUND_FLIGHT_STATUS_KEYS)[number]>value;
          }

          return { filters: { outboundFlightStatus } };
        },
      },
    ],
  },
};

export const getSchemaVersions = () => Object.keys(SCHEMAS);

export const getLatestSchemaVersion = () => getSchemaVersions().sort().pop();

export const getSchemaByVersion = (version?: string) => {
  if (version && version in SCHEMAS) {
    return SCHEMAS[version];
  }

  throw new Error(`Unknown schema version: ${version}. Available versions: ${getSchemaVersions().join(", ")}`);
};

export const getLatestSchema = () => getSchemaByVersion(getLatestSchemaVersion());

export const getSchemaVersionToken = (version: string) => {
  return SCHEMA_VERSION_TOKEN.replace("{id}", version);
};

export const getSchemaVersionFromCSV = (csv: string) => {
  const match = csv.match(SCHEMA_VERSION_RE);

  if (match) {
    return match.at(1);
  }

  throw new Error("No schema version found in CSV");
};
