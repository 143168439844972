import { useMemo } from "react";
import { observer } from "mobx-react";
import { Modal } from "@frontend/assaia-ui";

import { Pts, PtsSchedule } from "@models/pts";
import PtsOperationOptionsScreen from "./PtsOperationOptionsScreen";
import { PtsOperationModalStore } from "@stores/PtsOperationModalStore";
import PtsOperationMainScreen from "./PtsOperationMainScreen";
import { PtsOperationModalStoreContext } from "@services/react";

import styles from "./style.module.scss";
import { PtsPageStore } from "@stores/PtsPageStore";

type Props = {
  pts: Pts;
  schedule: PtsSchedule;
  ptsStore: PtsPageStore;
  onClose: () => void;
};

export const PtsOperationModal = observer(({ pts, schedule, ptsStore, onClose }: Props) => {
  const ptsScheduleStore = useMemo(
    () => new PtsOperationModalStore(ptsStore, pts, schedule),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const { operationScreen, setScreen } = ptsScheduleStore;

  return (
    <PtsOperationModalStoreContext.Provider value={ptsScheduleStore}>
      <Modal onClose={onClose} className={styles.ptsOperationModal}>
        {operationScreen === "main" && <PtsOperationMainScreen onClose={onClose} />}

        {operationScreen === "start" && (
          <PtsOperationOptionsScreen dataKey={"start"} onBack={() => setScreen("main")} />
        )}

        {operationScreen === "end" && <PtsOperationOptionsScreen dataKey={"end"} onBack={() => setScreen("main")} />}
      </Modal>
    </PtsOperationModalStoreContext.Provider>
  );
});
