import React from "react";

import styles from "./style.module.scss";
import { observer } from "mobx-react";
import { Icons24CheckCircle, Icons24Remove } from "@frontend/assaia-ui";
import { FormattedMessage } from "react-intl";

type Props = {
  active: boolean;
  title?: string;
};

const ActiveCell: React.FC<Props> = ({ active, title }) => {
  return (
    <div className={styles.container} title={title}>
      {active ? (
        <>
          <Icons24CheckCircle className={styles.green} />
          <span className={styles.text}>
            <FormattedMessage defaultMessage="Yes" description="Modal active toggle:yes" />
          </span>
        </>
      ) : (
        <>
          <Icons24Remove className={styles.red} />
          <span className={styles.text}>
            <FormattedMessage defaultMessage="No" description="Modal active toggle:no" />
          </span>
        </>
      )}
    </div>
  );
};

export default observer(ActiveCell);
