import { NewPts, Pts, PtsBase, PtsSchedule } from "@models/pts";
import { parseCsvLine, parseValue, serializeValue } from "@services/csv/utils";
import { merge } from "lodash";
import { getSchemaByVersion, getSchemaVersionFromCSV, getSchemaVersionToken } from "./schema";
import { ptsScheduleFromCSV, ptsScheduleToCSV } from "./ptsSchedule";

export const ptsListToCSV = (ptsList: (NewPts | Pts)[], version: string): string => {
  if (!ptsList || !ptsList.length) {
    throw new Error("PTS list is empty");
  }

  const schema = getSchemaByVersion(version);
  const result = [getSchemaVersionToken(version)];

  for (const pts of ptsList) {
    const id = pts.id || null;

    const header = schema.pts.map((column) => column.title).join(schema.separator);
    const row = schema.pts
      .map((column) => {
        return serializeValue(column.export(pts));
      })
      .join(schema.separator);

    // Empty string is used to add EOL
    const content = [header, row, ""];

    if (pts.schedules && pts.schedules.length > 0) {
      const schedulesCSV = ptsScheduleToCSV(pts.schedules, schema);

      content.push(schema.wrapScheduleBlock(schedulesCSV));
    }

    result.push(schema.wrapPtsBlock(id, content.join(schema.eol)), "");
  }

  return result.join(schema.eol);
};

export const ptsListFromCSV = (csv: string): (NewPts | Pts)[] => {
  if (!csv) {
    throw new Error("Passed CSV is empty");
  }

  const version = getSchemaVersionFromCSV(csv);
  const schema = getSchemaByVersion(version);

  const result: (NewPts | Pts)[] = [];

  for (const [, groupStartID, groupContent, groupEndID] of csv.matchAll(schema.ptsBlockRegex)) {
    if (groupStartID && groupEndID && groupStartID !== groupEndID) {
      throw new Error(`Group start ID ${groupStartID} does not match end ID ${groupEndID}`);
    }

    // Used to remove schedules from the group content
    let cleanGroupContent = groupContent;

    const schedules: PtsSchedule[] = [];

    for (const [matched, content] of groupContent.matchAll(schema.schedulesBlockRegex)) {
      // Remove the matched content from the group content
      cleanGroupContent = cleanGroupContent.replace(matched, "").trim();

      const trimmedContent = content?.trim();
      if (!trimmedContent) {
        continue;
      }

      schedules.push(...ptsScheduleFromCSV(trimmedContent));
    }

    if (!cleanGroupContent?.length) {
      throw new Error(`No content found in the group ${groupStartID || groupEndID}`);
    }

    const [headerRow, dataRow] = cleanGroupContent.split(schema.eolRe).filter(Boolean);

    const columns = parseCsvLine(headerRow, schema.separator).map(parseValue);
    const values = parseCsvLine(dataRow, schema.separator).map(parseValue);

    if (columns.length !== schema.pts.length) {
      throw new Error(`Header length doesn't match schema length: ${columns.length} !== ${schema.pts.length}`);
    }

    if (values.length !== columns.length) {
      throw new Error(
        `Row length doesn't match header length: ${values.length} !== ${columns.length}.\n${values.join(", ")}\n${columns.join(", ")}`,
      );
    }

    const pts = <PtsBase & Partial<Pts>>{};

    for (let index = 0; index < values.length; index++) {
      const value = values[index];
      const column = schema.pts[index];

      if (column) {
        merge(pts, column.import(value));
      } else {
        throw new Error(`Unknown column: ${columns[index]}`);
      }
    }

    if (pts.id !== groupStartID || pts.id !== groupEndID) {
      throw new Error(`PTS ID ${pts.id} does not match group start ID ${groupStartID} or end ID ${groupEndID}`);
    }

    const id = pts.id || groupStartID || groupEndID;

    if (id) {
      pts.id = id;
    } else {
      delete pts.id;
    }

    pts.schedules = schedules;

    result.push(pts);
  }

  return result;
};
