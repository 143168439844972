import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { defineMessage, useIntl } from "react-intl";

import { ptsListToCSV } from "@services/csv/ptsList";
import { exportFile } from "@services/export";
import { ExportButton as BaseExportButton } from "@components/Common/ExportButton";

import { HomeContext } from "@services/react";

export const PtsExportButton = observer(() => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {
    setUIAlert,
    ptsPageStore: { ready, ptsList },
  } = useContext(HomeContext);

  // TODO: create store method
  const handleExport = () => {
    try {
      setIsProcessing(true);

      const csv = ptsListToCSV(ptsList, "1.0");

      exportFile("pts-list.csv", csv);
    } catch (error) {
      console.error(error);
      setUIAlert({
        message: defineMessage({
          defaultMessage: "Error exporting CSV",
          description: "Error exporting CSV alert message",
        }),
        type: "error",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const isDisabled = !ready || isProcessing;

  return (
    <BaseExportButton disabled={isDisabled} onClick={handleExport}>
      {intl.formatMessage({
        defaultMessage: "Export CSV",
        description: "Export CSV button label",
      })}
    </BaseExportButton>
  );
});
