import React, { useContext, useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { observer } from "mobx-react";
import { ModalLabel, Chip, Input, ModalIcon, ModalCell, ModalHeader } from "@frontend/assaia-ui";

import RedStar from "@components/Common/RedStar";
import { ReactComponent as TriggerSVG } from "@assets/trigger.svg";
import { ReactComponent as ResetSVG } from "@assets/reset.svg";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";
import { IncidentModalStoreContext } from "@services/react";
import ModalSmallDropdown from "@components/Common/ModalSmallDropdown";
import TurnaroundAttributeSelector from "../TurnaroundAttributeSelector";
import { ModalSpacer } from "@components/Common/ModalSpacer";

import styles from "./style.module.scss";

const titleMap = defineMessages({
  startRangeBoundary: {
    defaultMessage: "Start monitoring",
    description: "incident modal:timing screen:start monitoring",
  },
  firingPointInTime: {
    defaultMessage: "Firing point",
    description: "incident modal:timing screen:firing point",
  },
  endRangeBoundary: {
    defaultMessage: "Stop monitoring",
    description: "incident modal:timing screen:stop monitoring",
  },
  attributeSettings: {
    defaultMessage: "attributeSettings;",
    description: "incident modal:timing screen:attribute settings",
  },
});
const IncidentTimingScreenSettings = () => {
  const {
    timeShift,
    closeModal,
    setScreen,
    selectedAttributeKey,
    resetAttributeSettings,
    setTimingTimeShift,
    markScreenAsShown,
  } = useContext(IncidentModalStoreContext);
  const intl = useIntl();

  useEffect(() => () => markScreenAsShown(selectedAttributeKey), []);

  return (
    <>
      <ModalHeader
        onBack={() => setScreen("timing")}
        onClose={closeModal}
        icon={<TriggerSVG />}
        title={intl.formatMessage(titleMap[selectedAttributeKey])}
      >
        <ModalSmallDropdown
          trigger={
            <ModalIcon onClick={() => void 0}>
              <DotsSVG />
            </ModalIcon>
          }
        >
          <ModalCell
            onClick={resetAttributeSettings}
            title={
              <span>
                <FormattedMessage defaultMessage="Reset" description="incident modal:timing screen" />
              </span>
            }
            leftIcon={<ResetSVG />}
          />
        </ModalSmallDropdown>
      </ModalHeader>
      <TurnaroundAttributeSelector
        sourceTitle={intl.formatMessage({
          defaultMessage: "start configuration",
          description: "incident modal:timing screen",
        })}
      />
      <ModalLabel
        title={
          <RedStar>
            <FormattedMessage defaultMessage="Time shift" description="incident modal:timing screen" />
          </RedStar>
        }
      >
        <Input
          value={`${timeShift}`}
          onChange={setTimingTimeShift}
          suffix={intl.formatMessage({
            defaultMessage: "Min",
            description: "incident modal:timing screen timeshift minimim suffix",
          })}
        />
      </ModalLabel>
      <ModalLabel
        className={styles.caption}
        title={intl.formatMessage({
          defaultMessage: "Popular time shifts",
          description: "incident modal:timing screen",
        })}
      >
        <div className={styles.buttons}>
          <Chip onClick={() => setTimingTimeShift(-5)}>
            <FormattedMessage defaultMessage="-5m" description="incident modal:popular time shifts" />
          </Chip>
          <Chip onClick={() => setTimingTimeShift(5)}>
            <FormattedMessage defaultMessage="5m" description="incident modal:popular time shifts" />
          </Chip>
          <Chip onClick={() => setTimingTimeShift(10)}>
            <FormattedMessage defaultMessage="10m" description="incident modal:popular time shifts" />
          </Chip>
        </div>
      </ModalLabel>
      <ModalSpacer />
    </>
  );
};

export default observer(IncidentTimingScreenSettings);
