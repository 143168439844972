import { AxiosError } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import User, { NewUser } from "../../models/user";
import Role from "../../models/role";
import { exportUsersToCSV } from "@services/export";
import { keyBy, sortBy, uniqBy } from "lodash";
import { defineMessage, defineMessages } from "react-intl";
import { HomeStore } from "../HomeStore";
import { TUsersApi } from "@api/userApi";

const APPROX_USER_ROW_HEIGHT = 40; // px
const USER_SEARCH_CHAR_THRESHOLD = 1; // string length

const messages = defineMessages({
  activate: {
    defaultMessage: "Are you sure to activate the {count, plural, one {user} other {users}}",
    description: "Activate/deactivate user",
  },
  deactivate: {
    defaultMessage: "Are you sure to activate the {count, plural, one {user} other {users}}",
    description: "Activate/deactivate user",
  },
  deleteUsers: {
    defaultMessage: "Are you sure to delete the {count, plural, one {user} other {users}}",
    description: "Delete user",
  },
  resetPassword: {
    defaultMessage: "Are you sure to reset the {count, plural, one {password} other {passwords}}",
    description: "Reset password",
  },
  resetOTPToken: {
    defaultMessage: "Are you sure to reset the {count, plural, one {OTP token} other {OTP tokens}}",
    description: "Reset OTP token",
  },
  personalLink: {
    defaultMessage: "Are you sure to send personal link",
    description: "Send personal link",
  },
});

export class UsersPageStore {
  selectedUsersIds: number[] = [];
  searchQuery = "";
  userModalData: User | NewUser | null = null;
  users: User[] = [];
  roles: Role[] = [];
  ready = false;
  highlightItemId: number | null = null;

  currentPage = 0;
  pageSize = 10;
  limitHasReached = false;
  exportingUsers = false;
  loading = false;

  requestId: number | null = null;

  constructor(
    private home: HomeStore,
    private api: TUsersApi,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get usersMap() {
    return keyBy(this.users, "id");
  }

  get rolesMap() {
    return keyBy(this.roles, "id");
  }

  get moreUsersLoading() {
    return this.requestId !== null;
  }

  get filteredUsers() {
    let { users } = this;
    const { searchQuery } = this;

    if (searchQuery && searchQuery.length >= 2) {
      const query = searchQuery.toLowerCase().trim();
      users = users.filter((u) => {
        const userNameMatch = u.profile.full_name && u.profile.full_name.toLowerCase().includes(query);
        const emailMatch = u.email.toLowerCase().includes(query);

        return userNameMatch || emailMatch;
      });
    }

    return sortBy(users, "email");
  }

  async init(initialPageSize?: number) {
    if (this.ready) {
      return;
    }

    try {
      // Make page size according with window height
      this.pageSize = initialPageSize || Math.ceil((window?.innerHeight || 1000) / APPROX_USER_ROW_HEIGHT);
      const p1 = this.api.getUsers({
        page_number: this.currentPage,
        page_size: this.pageSize,
      });
      const p2 = this.api.getRoles();

      const [users, roles] = await Promise.all([p1, p2]);
      runInAction(() => {
        this.users = users;
        this.roles = roles;
      });
    } catch (error) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: something went wrong",
          description: "Error message when users could not be loaded",
        }),
        type: "error",
      });
      throw error;
    } finally {
      runInAction(() => {
        this.ready = true;
      });
    }
  }

  /**
   * Opens modal
   */
  createUser() {
    const user: UsersPageStore["userModalData"] = {
      active: true,
      email: "",
      profile: {
        company: null,
        full_name: null,
        phone: null,
      },
      roles: [],
      phone: null,
      token: "",
    };

    this.userModalData = user;
  }

  /**
   * Opens modal
   */
  editUser(user?: User) {
    this.userModalData = user || null;
  }

  async saveUser(data: User | NewUser) {
    const user = await ("id" in data ? this._handlePutUser(data) : this._handleCreateUser(data));

    user && this.setHighlightItem(user.id);
  }

  async resetPassword(ids: number[]) {
    await this.home.showConfirmModal(messages.resetPassword, {
      count: ids.length,
    });

    try {
      this._setLoading(true);

      const result = await Promise.all(ids.map((id) => this.api.resetPassword(id)));

      // TODO: Add list with emails for failed delivery status
      const failed = result.filter((v) => v.email?.delivery_status === "FAILED");
      if (failed.length) {
        const apiErrorText = failed.length === 1 && failed[0].email?.error;

        const defaultMessage = defineMessage({
          defaultMessage: "Email has not been sent for some users",
          description: "Error message when email has not been sent",
        });

        this.home.setUIAlert({
          message: apiErrorText
            ? {
                id: "userStore#resetPassword[failed.email.error]",
                defaultMessage: apiErrorText,
              }
            : defaultMessage,
          type: "error",
        });
        return;
      }

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage:
            "Reset password {count, plural, one {link has been sent to the user} other {links have been sent to the users}}",
          description: "Message indicating the number of reset password links sent",
        }),
        type: "info",
        values: { count: ids.length },
      });
    } catch (error) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: the password has not been reset",
          description: "Error message when password has not been reset",
        }),
        type: "error",
      });
      throw error;
    } finally {
      this._setLoading(false);
    }
  }

  async resetOTPToken(ids: number[]) {
    await this.home.showConfirmModal(messages.resetOTPToken, {
      count: ids.length,
    });

    try {
      this._setLoading(true);

      const result = await Promise.all(ids.map((id) => this.api.resetOTPToken(id)));

      // TODO: Add list with emails for failed delivery status
      const failed = result.filter((v) => v.email?.delivery_status === "FAILED");
      if (failed.length) {
        const apiErrorText = failed.length === 1 && failed[0].email?.error;

        const defaultMessage = defineMessage({
          defaultMessage: "Email has not been sent for some users",
          description: "Error message when email has not been sent",
        });

        this.home.setUIAlert({
          message: apiErrorText
            ? {
                id: "userStore#resetOTPToken[failed.email.error]",
                defaultMessage: apiErrorText,
              }
            : defaultMessage,
          type: "error",
        });

        return;
      }

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "OTP {count, plural, one {token has} other {tokens have}} been reset successfully",
          description: "Message indicating the number of OTP tokens that have been reset",
        }),
        type: "info",
        values: { count: ids.length },
      });
    } catch (error) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: the OPT token has not been reset",
          description: "Error message when OTP token has not been reset",
        }),
        type: "error",
      });
      throw error;
    } finally {
      this._setLoading(false);
    }
  }

  async sendPersonalLink(userId: number) {
    await this.home.showConfirmModal(messages.personalLink, {});

    try {
      this._setLoading(true);

      await this.api.resetToken(userId);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Personal link has been reset",
          description: "Success message when personal link has been reset",
        }),
        type: "info",
      });
    } catch (error) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: the personal link has not been reset",
          description: "Error message when personal link has not been reset",
        }),
        type: "error",
      });
      throw error;
    } finally {
      this._setLoading(false);
    }
  }

  async deleteUsers(ids: number[]) {
    await this.home.showConfirmModal(messages.deleteUsers, {
      count: ids.length,
    });

    try {
      this._setLoading(true);

      const result = await this.api.deleteUsers(ids);

      // TODO: Add list with emails for failed delivery status
      const failed = result.filter((v) => v.email?.delivery_status === "FAILED");
      if (failed.length) {
        const apiErrorText = failed.length === 1 && failed[0].email?.error;

        const defaultMessage = defineMessage({
          defaultMessage: "Email has not been sent for some users",
          description: "Error message when email has not been sent",
        });

        this.home.setUIAlert({
          message: apiErrorText
            ? {
                id: "userStore#deleteUsers[failed.email.error]",
                defaultMessage: apiErrorText,
              }
            : defaultMessage,
          type: "error",
        });
        return;
      }

      runInAction(() => {
        this.users = this.users.filter((v) => !ids.includes(v.id));
      });

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied. The system will be updated within 30 minutes.",
          description: "Success message when users have been deleted",
        }),
        type: "info",
      });
    } catch (error) {
      // Can throw an error
      this.home.handlePhoneError(error, false);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when users have not been deleted",
        }),
        type: "error",
      });
      throw error;
    } finally {
      this._setLoading(false);
    }
  }

  async toggleUsersActive(ids: number[], active: boolean) {
    await this.home.showConfirmModal(active ? messages.activate : messages.deactivate, { count: ids.length });

    const filteredUsers = this.users.filter(({ id }) => ids.includes(id)).map((n) => ({ ...n, active }));

    try {
      this._setLoading(true);

      const data = await this.api.toggleUsersActive(filteredUsers);

      data.map(({ user }) => this._updateUser(user));

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage:
            "{count, plural, one {User has been} other {Users have been}} {isActive, select, true {activated} other {deactivated}}",
          description: "Message indicating the activation or deactivation status of users",
        }),
        type: "info",
        values: {
          count: ids.length,
          isActive: active,
        },
      });
    } catch (error) {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when users have not been toggled",
        }),
        type: "error",
      });
      throw error;
    } finally {
      this._setLoading(false);
    }
  }

  setSelectedUsers(ids: number[]) {
    this.selectedUsersIds = ids;
  }

  setUserQuery(query: string) {
    this.searchQuery = query;
    this.selectedUsersIds = [];
  }

  async searchUsers(oldQuery: string, newQuery: string) {
    oldQuery = oldQuery.toLowerCase().trim();
    newQuery = newQuery.toLowerCase().trim();
    if (!oldQuery && newQuery.length <= USER_SEARCH_CHAR_THRESHOLD) {
      return;
    }

    this.limitHasReached = false;
    this.users = [];
    return this._handleLoadMoreUsers(0, newQuery);
  }

  async exportUsers() {
    try {
      this.exportingUsers = true;
      const allUsers = await this.api.getUsers();
      exportUsersToCSV(allUsers, this.roles);
    } catch {
      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Export users has failed",
          description: "Export users error message",
        }),
        type: "error",
      });
    } finally {
      runInAction(() => (this.exportingUsers = false));
    }
  }

  setHighlightItem(id: UsersPageStore["highlightItemId"]) {
    this.highlightItemId = id;
  }

  async loadMoreUsers() {
    if (this.moreUsersLoading) {
      return;
    }

    return this._handleLoadMoreUsers(this.currentPage + 1);
  }

  async nextPage() {
    if (!this.limitHasReached) {
      return this.loadMoreUsers();
    }
  }

  private async _handleLoadMoreUsers(targetPage: number, query = "") {
    const { pageSize } = this;

    const requestId = Date.now();
    this.requestId = requestId;

    const withoutPagination = query.length > USER_SEARCH_CHAR_THRESHOLD;
    try {
      const newUsers = await this.api.getUsers(
        withoutPagination
          ? {
              full_name: query,
              email: query,
            }
          : {
              page_number: targetPage,
              page_size: pageSize,
            },
      );

      if (this.requestId !== requestId) {
        return;
      }

      runInAction(() => {
        if (withoutPagination) {
          this.users = sortBy(newUsers, (v) => v.email);
          this.limitHasReached = true;
          return;
        }

        this.users = uniqBy([...this.users, ...newUsers], (v) => v.id);
        this.currentPage = targetPage;
        this.limitHasReached = newUsers.length < pageSize;
      });
    } finally {
      runInAction(() => {
        if (this.requestId === requestId) {
          this.requestId = null;
        }
      });
    }
  }

  private async _handleCreateUser(data: NewUser) {
    try {
      this._setLoading(true);

      const { user, email } = await this.api.createUser(data);

      // If an email hasn't been sent, a new user WILL NOT be created on backend
      // if (email?.delivery_status === "FAILED") {
      if (email?.delivery_status === "FAILED") {
        const message = email.error
          ? {
              id: "userStore#_handleCreateUser[email.error]",
              defaultMessage: email.error,
            }
          : defineMessage({
              defaultMessage: "Error: changes have not been applied",
              description: "Error message when user has not been added",
            });

        this.home.setUIAlert({
          message,
          type: "error",
        });

        return;
      }

      this._updateUser(user);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The user has been added",
          description: "Success message when user has been added",
        }),
        type: "info",
      });

      return user;
    } catch (error) {
      const apiErrorMessage = (error as AxiosError<any>)?.response?.data?.error;
      const message = apiErrorMessage
        ? {
            id: "userStore#_handleCreateUser[apiErrorMessage]",
            defaultMessage: apiErrorMessage,
          }
        : defineMessage({
            defaultMessage: "Error: changes have not been applied",
            description: "Error message when user has not been added",
          });

      this.home.setUIAlert({
        message,
        type: "error",
      });

      throw error;
    } finally {
      this._setLoading(false);
    }
  }

  private async _handlePutUser(data: User) {
    try {
      this._setLoading(true);
      const { user, email } = await this.api.updateUser(data);

      this._updateUser(user);

      // If an email hasn't been sent, a user WILL BE updated on backend
      if (email?.delivery_status === "FAILED") {
        const message = email.error
          ? {
              id: "userStore#_handlePutUser[email.error]",
              defaultMessage: email.error,
            }
          : defineMessage({
              defaultMessage: "Error: an email has not been sent",
              description: "Error message when email has not been sent",
            });

        this.home.setUIAlert({
          message,
          type: "error",
        });
        return;
      }

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "The changes have been applied",
          description: "Success message when user has been updated",
        }),
        type: "info",
      });

      return user;
    } catch (error) {
      // Can throw an error
      this.home.handlePhoneError(error);

      this.home.setUIAlert({
        message: defineMessage({
          defaultMessage: "Error: changes have not been applied",
          description: "Error message when user has not been updated",
        }),
        type: "error",
      });
      throw error;
    } finally {
      this._setLoading(false);
    }
  }

  private _updateUser(data: User) {
    const idx = this.users.findIndex((n) => n.id === data.id);
    if (idx < 0) {
      this.users.push(data);
      return;
    }

    this.users[idx] = data;
  }

  private _setLoading(v: boolean) {
    this.loading = v;
  }
}
