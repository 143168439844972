type LocalStorageReturnType = {
  error?: Error;
};

export function writeToLocalStorage<T>(key: string, value: T): LocalStorageReturnType {
  try {
    localStorage.setItem(key, JSON.stringify(value));

    return {};
  } catch (maybeError) {
    const error = maybeError instanceof Error ? maybeError : new Error("Unable to write localStorage data.");

    return { error };
  }
}

export function readFromLocalStorage<T>(
  key: string,
  defaultValue: T,
): {
  value: T;
  error?: Error;
} {
  try {
    const raw = localStorage.getItem(key);

    if (raw) {
      const parsed = JSON.parse(raw);
      return {
        value: parsed,
      };
    }

    return {
      value: defaultValue,
    };
  } catch (maybeError) {
    const error = maybeError instanceof Error ? maybeError : new Error("Unable to read localStorage data.");

    return {
      value: defaultValue,
      error,
    };
  }
}
