import React, { useCallback, useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { TableColumn, Chip, Table } from "@frontend/assaia-ui";

import ActiveCell from "@components/Common/ActiveCell";
import { cn, HomeContext } from "@services/react";
import User from "@models/user";
import { getConfig } from "@di";
import styles from "./style.module.scss";

// TODO: It seems broken, use CSS modules
const getRowClass = (u: User) => (u.active ? "active" : "disabled");
const getRowKey = (u: User) => u.id;

const UsersTable = () => {
  const { enableDutyPhones } = getConfig();
  const { usersPageStore } = useContext(HomeContext);
  const {
    rolesMap,
    users,
    editUser,
    setSelectedUsers,
    selectedUsersIds: selectedUsers,
    highlightItemId,
    setHighlightItem,
    moreUsersLoading,
  } = usersPageStore;
  const intl = useIntl();

  const columns = useMemo<TableColumn[]>(() => {
    const res = [
      {
        title: intl.formatMessage({
          defaultMessage: "Full name",
          description: "User's full name",
        }),
        width: "30%",
        render: (user: User) => user.profile.full_name,
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Email",
          description: "User's email",
        }),
        width: "20%",
        render: (user: User) => user.email,
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Company",
          description: "User's company",
        }),
        width: "15%",
        render: (user: User) => user.profile.company,
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Roles",
          description: "User's roles",
        }),
        render: (user: User) => (
          <div className={styles.chipContainer}>
            {user.roles.map((id: any) => (
              <Chip key={id} className={styles.userChip} hoverable={false}>
                {rolesMap[id]?.description || id}
              </Chip>
            ))}
          </div>
        ),
      },
      {
        title: intl.formatMessage({
          defaultMessage: "Active",
          description: "User's active status",
        }),
        width: 1,
        getCellClass: (user: User) => cn("bold", { green: user.active }),
        render: (user: User) => <ActiveCell active={user.active} />,
      },
    ];

    if (enableDutyPhones) {
      res.splice(2, 0, {
        title: intl.formatMessage({
          defaultMessage: "Phone",
          description: "User's phone",
        }),
        width: "10%",
        render: (user: User) => user.phone?.phone || null,
      });
    }

    return res;
  }, [rolesMap, intl]); // Add intl to the dependency array

  const onSelect = useCallback((ids: number[]) => setSelectedUsers(ids), []);

  return (
    <Table
      stickyThead
      loadingItem={moreUsersLoading}
      enablePagination={false}
      columns={columns}
      items={users}
      getRowClass={getRowClass}
      getRowKey={getRowKey}
      selectedItems={selectedUsers}
      onSelect={onSelect}
      onRowClick={(item: User) => editUser(item)}
      className={styles.usersTable}
      highlightItemId={highlightItemId}
      onHighlightEnd={() => setHighlightItem(null)}
    />
  );
};

export default observer(UsersTable);
