export const isFunction = <T>(
  value: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): value is T extends (...args: any) => any ? T : never => {
  return typeof value === "function";
};

export const isFiniteNumber = (value: unknown): value is number => Number.isFinite(value);

// Converts passed string (including string literals) to lowercase.
// Returns null if the input is not a string.
export function toLowerCase<T>(value: T): T extends string ? Lowercase<T> : null;
export function toLowerCase(value: unknown): string | null {
  if (typeof value === "string") {
    return value.toLowerCase();
  }

  return null;
}

// Converts passed string (including string literals) to uppercase.
// Returns null if the input is not a string.
export function toUpperCase<T>(value: T): T extends string ? Uppercase<T> : null;
export function toUpperCase(value: unknown): string | null {
  if (typeof value === "string") {
    return value.toUpperCase();
  }

  return null;
}
